import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {Program} from '../model/program.model';
import {College} from '../model/application/college.model';
import {ELevelCategory, EProgLevel} from '../model/prog-level.model';
import {CampusLevelProgramArray} from '../model/campus-level-program-array.model';
import {switchMap, timeout} from 'rxjs/operators';
import {EmailService} from 'src/app/shared/service/email.service';
import {EmailMessage, EmailType} from 'src/app/shared/model/email-message.model';

@Injectable({
  providedIn: 'root'
})

export class ProgramService {

  allPrograms: Program[] = [];

  private programsByCampusLevel = new CampusLevelProgramArray(); // all programs in campus/level combos as simple arrays
  public pickerPrograms = new CampusLevelProgramArray(); // the final prepared Object to  display in the pickers, has a different structure
  private pickerSelectedSubject = new BehaviorSubject(new Program('')); // this is mostly for the degree-picker-modal to emit the program selected to the degree-picker before the main programSelectedSubject is emitted
  private programSelectedSubject = new BehaviorSubject(new Program(''));

  private programsLoaded = new BehaviorSubject(false);
  private allProgramsLoaded = new BehaviorSubject(false);

  private currentProgram = new Program();
  private currentLevelCategory: ELevelCategory;

  constructor(
    private http: HttpClient,
    private emailService: EmailService
  ) {

    this.currentProgram.programCode = '';

    this.programSelectedSubject.subscribe(program => {
      this.currentProgram = program;
      this.currentLevelCategory = this.getLevelCategoryByProgramCode(program.programCode);
    });
  }

  getAllProgramsLoadedSub(): Observable<boolean> {
    return this.allProgramsLoaded.asObservable();
  }

  setCurrentProgram(program: Program) {
    this.currentProgram = program;
  }

  public getCurrentLevelCategory(): ELevelCategory {
    return this.currentLevelCategory;
  }

  getPickerSelectedSub(): Observable<Program> {
    return this.pickerSelectedSubject.asObservable();
  }

  getProgramSub(): Observable<Program> {
    return this.programSelectedSubject.asObservable();
  }

  pickerSelectedSubNext(program: Program) {
    this.pickerSelectedSubject.next(program);
  }

  programSubNext(program: Program) {
    this.programSelectedSubject.next(program);
  }

  generateEmptyProgram() {
    const returnedProgram = new Program();
    returnedProgram.programCode = '';
    returnedProgram.level = '';
    returnedProgram.levelCode = '';
    returnedProgram.campCode = '';
    return returnedProgram;
  }

  prepareAllProgramsByCampusLevel() {

    const returnedPrograms = new CampusLevelProgramArray();

    this.allPrograms.forEach((thisProgram: Program) => {
      if (!thisProgram.programCode) {
        return;
      }

      const progCampus = thisProgram.campCode;

      let progLevel = thisProgram.degreeLevel;
      if (progLevel === 'Less than Associate') {
        progLevel = 'Associate';
      } else if (progLevel === 'Law Degree') {
        progLevel = 'Doctorate';
      }
      returnedPrograms[progCampus][progLevel].push(thisProgram);

    });

    this.programsByCampusLevel = returnedPrograms;
  }

  returnProgramsByCampusLevel(): CampusLevelProgramArray {
    return this.programsByCampusLevel;
  }

  getProgramByProgramCodeAndName = (programCode: string, programName: string): Program => {
    if (!programCode && !programName)
      return this.generateEmptyProgram();
    const foundProgram = this.allPrograms.find(program => program.programCode === programCode.toUpperCase()
      && (program.cognate || program.majorGroup) === programName);

    return foundProgram || this.generateEmptyProgram();
  }

  getProgramByProgramCode = (programCode = ''): Program => {
    if (!programCode) {
      return this.generateEmptyProgram();
    }
    const foundProgram = this.allPrograms.find(program => program.programCode === programCode.toUpperCase());
    if (foundProgram) {
      return foundProgram;
    } else {
      return this.generateEmptyProgram();
    }
  }

  getCampusByProgramCode = (programCode = ''): string => {
    if (!programCode) {
      return '';
    }
    const theProgram = this.allPrograms.find(program => program.programCode === programCode.toUpperCase());
    if (theProgram) {
      return theProgram.campCode;
    } else {
      return '';
    }
  }

  getLevelByProgramCode = (programCode: string): EProgLevel => {
    if (!programCode) {
      return null;
    }
    const allProgramsArray: Program[] = Object.values(this.allPrograms);
    const theProgram = allProgramsArray.find(program => program.programCode === programCode.toUpperCase());
    let returnedEnum: EProgLevel;

    if (theProgram) {
      const degreeLevelString = theProgram.degreeLevel;
      returnedEnum = EProgLevel[degreeLevelString];

      if (theProgram.degreeLevel === 'Certificate' && theProgram.level === 'Graduate') {
        returnedEnum = EProgLevel.Master;
      } else if (theProgram.degreeLevel === 'Certificate' && theProgram.level === 'Doctorate') {
        returnedEnum = EProgLevel.Doctorate;
      } else if (degreeLevelString === 'Less than Associate') {
        returnedEnum = EProgLevel.LessthanAssociate;
      } else if (degreeLevelString === 'Law Degree') {
        returnedEnum = EProgLevel.Doctorate;
      }
      if (returnedEnum) {
        return returnedEnum;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getEProgLevelByDegreeLevel = (level: string, degreeLevel: string): EProgLevel => {
    let returnedEnum: EProgLevel;

    if (degreeLevel && degreeLevel !== '') {
      returnedEnum = EProgLevel[degreeLevel];
      if (degreeLevel === 'Certificate' && level === 'Graduate') {
        returnedEnum = EProgLevel.Master;
      } else if (degreeLevel === 'Certificate' && level === 'Doctorate') {
        returnedEnum = EProgLevel.Doctorate;
      } else if (level === 'Less than Associate') {
        returnedEnum = EProgLevel.LessthanAssociate;
      } else if (level === 'Law Degree') {
        returnedEnum = EProgLevel.Doctorate;
      }
      if (returnedEnum) {
        return returnedEnum;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getLevelCategoryByProgramCode = (programCode: string): ELevelCategory => {
    if (!programCode) {
      return null;
    }
    const allProgramsArray: Program[] = Object.values(this.allPrograms);
    const theProgram = allProgramsArray.find(program => program.programCode === programCode.toUpperCase());
    if (theProgram && this.isUndergrad(theProgram)) {
      return ELevelCategory.Undergrad;
    } else if (theProgram && (this.isGraduate(theProgram) || this.isGraduateOrDoctorateAndRequiresMasters(theProgram) || this.isExecutiveDoctoral(theProgram) || this.isPHD(theProgram))) {
      return ELevelCategory.Graduate;
    } else {
      return ELevelCategory.Undergrad;
    }
  }

  public isUndergrad(program: Program): boolean {
    return ['DL', 'DT', 'DA', 'LB', 'CU', 'RU', 'RR', 'CR'].some((wapp) => program.wappCode === wapp);
  }

  public isGraduate(program: Program): boolean {
    return ['GR', 'RG', 'CG', 'CD'].some((el) => program.wappCode === el);
  }

  public isGraduateOrDoctorateAndRequiresMasters(program: Program): boolean {
    const doctoralWapps = ['DR', 'RD', 'CL'];
    const graduateWapps = ['CM'];
    return [...doctoralWapps, ...graduateWapps].some((el) => program.wappCode === el) || program.levelCode === 'JD';
  }

  public isExecutiveDoctoral(program: Program): boolean {
    return program.wappCode === 'DO' || program.wappCode === 'RO';
  }

  public isPHD(program: Program): boolean {
    return program.wappCode === 'DC' || program.wappCode === 'RC';
  }

  public requiresMasterAndBachelor(program: Program): boolean {
    return ['DB', 'RB', 'DH', 'RH'].some((el) => program.wappCode === el);
  }

  public requiresMasterOrBachelor(program: Program): boolean {
    return ['DO', 'RO'].some((el) => program.wappCode === el);
  }

  gatherWappCode(levelCode: string, level: string, degreelevel: string, campus: string, providedColleges: College[] = [], wapp: string): string {
    /*
    CAMPUS	LEVEL	                      WAPP_CODES	                            WAPP DESC (s)
    D	      Doctorate	                  DR	                                    Online Post-Master's Degrees
    D	      Doctorate-Certificate	      CL (degreeLevel is Certificate)	        Certificate (Doctoral)
    D	      Graduate	                  GR	                                    Online Master's
    D	      Graduate-Certificate	      CG (degree code equals 'CTG')	          Certificate (Graduate)
    D	      Undergraduate	              DL: new | DT: transfer | DA: readmit	  Online Undergraduate: New, Transfer, Re-Admit
    D	      Undergraduate-Certificate	  CU (degree code equals 'CRT')	          Certificate (Undergraduate)
    D	      Institute-Certificate	      LB	                                    Online Wilmington School

    CAMPUS	LEVEL	                      WAPP_CODES	                            WAPP DESC (s)
    R	      Doctorate	                  RD	                                    Resident Doctoral Degree
    R	      Graduate	                  RG	                                    Resident Graduate Degree
    R	      Graduate-Certificate	      CD  (degree code equals 'CTG')	  	    Certificate (GR -Res)
    R	      Undergraduate	              RU: new | RT: transfer | RR: readmit  	Resident Undergrad: New, Transfer, Re-Admit
    R	      Undergraduate-Certificate	  CR (degree code equals 'CRT')	          Certificate (UG -Res)

    for both campus, undergrad programs have 3 possible WAPP codes depending on what priorColleges (if any) they have indicated
    the other program levels are more straightforward
    */

    let returnedWappCode = wapp;
    const eProgLevel = this.getEProgLevelByDegreeLevel(level, degreelevel);

    if (campus === 'D') { // online campus
      if (levelCode === 'UG' || eProgLevel === EProgLevel.Bachelor || eProgLevel === EProgLevel.Associate) { // undergrad
        /* overwrite depending on what priorColleges they've listed
         if they have attended any other colleges at a Bachelors level, they get DT
         if they have attended LU at a Bachelors level, they get DA*/
        let isTransferStudent = false;
        let hasAttendedLu = false;
        for (const college of providedColleges) {
          if (college.degree) {
            isTransferStudent = true;
            if (college.ceebCode === '005385') { // if they attended LU
              hasAttendedLu = true;
            }
            if (isTransferStudent) {
              returnedWappCode = 'DT';
            }
            if (hasAttendedLu) {
              returnedWappCode = 'DA';
            }
          }
        }
      }


    } else if (campus === 'R') { // residential campus
      if (levelCode === 'UG' || eProgLevel === EProgLevel.Bachelor || eProgLevel === EProgLevel.Associate) { // undergrad
        // overwite depending on what priorColleges they've listed
        // if they have attended any other colleges at a Bachelors level, they get RT
        // if they have attended LU at a Bachelors level, they get RR
        let isTransferStudent = false;
        let hasAttendedLu = false;
        for (const college of providedColleges) {
          if (college.degree) {
            isTransferStudent = true;
            if (college.ceebCode === '005385') { // if they attended LU
              hasAttendedLu = true;
            }
          }
        }
        if (hasAttendedLu) {
          returnedWappCode = 'RR';
        } else if (isTransferStudent) {
          returnedWappCode = 'RT';
        }

      }
    }
    return returnedWappCode;
  }

  public getCurrentProgram(): Program {
    return this.currentProgram;
  }

  initNestedPrograms() {
    this.pickerPrograms = new CampusLevelProgramArray();

    return new Promise((resolve) => {
      this.http.get<string>(
        `/rest/nestedprograms`,
        {responseType: 'json'}
      )
        .pipe(
          timeout(8000), // if request takes 8 seconds, will throw error
        )
        .subscribe(
          nestedProgramsResponse => {
            this.resolveProgramsEndpoint(nestedProgramsResponse);
            resolve(true);


          },
          () => {
            // load backup json file
            this.http.get('assets/cache/nestedPrograms.json').subscribe((nestedProgramsResponse: string) => {

              this.resolveProgramsEndpoint(nestedProgramsResponse);
              resolve(true);
            });
          }
        );
    });
  }

  initAllPrograms() {
    return new Promise((resolve) => {
      this.http.get<Program[]>(
        `/rest/programs`,
        {responseType: 'json'}
      )
        .pipe(
          timeout(8000), // if request takes 8 seconds, will throw error
        )
        .subscribe(
          response => {
            this.allPrograms = response;
            this.prepareAllProgramsByCampusLevel();
            this.allProgramsLoaded.next(true);
            resolve(true);
          },
          () => {
            // load backup json file
            this.http.get('assets/cache/programs.json').subscribe(programs => {
              Object.assign(this.allPrograms, programs);
              this.prepareAllProgramsByCampusLevel();
              this.allProgramsLoaded.next(true);
              resolve(true);
            });
          }
        );
    });
  }

  checkForRequiredProps(program: Program): Program {

    const campCode = program.campCode;
    const level = program.level;

    // check for wapp code
    // if all of the above are false then there is a problem
    if ([this.isUndergrad(program), this.isGraduate(program), this.isGraduateOrDoctorateAndRequiresMasters(program), this.isExecutiveDoctoral(program), this.isPHD(program), this.requiresMasterAndBachelor(program), this.requiresMasterOrBachelor(program)].every(value => value === false)) {
      // just set it to undergrad, graduate, or doctorate based on what we know about the program

      // also send an email
      const emailHeader = new EmailMessage(EmailMessage.NO_REPLY, EmailMessage.SAT, EmailMessage.NO_REPLY, `Invalid wapp code for ProgramCode: ${program.programCode}`, `Program came in with an invalid wapp code. ProgramCode: ${program.programCode}, with wapp code: ${program.wappCode}`, EmailType.API_ERROR);
      this.emailService.sendEmail(emailHeader).subscribe();

      switch (level + campCode) {
        case 'Undergraduate' + 'D':
          program.wappCode = 'DL';
          break;

        case 'Graduate' + 'D':
          program.wappCode = 'RG';
          break;

        case 'Doctorate' + 'D':
          program.wappCode = 'DR';
          break;

        case 'Undergraduate' + 'R':
          program.wappCode = 'RU';
          break;

        case 'Graduate' + 'R':
          program.wappCode = 'RG';
          break;

        case 'Doctorate' + 'R':
          program.wappCode = 'RD';
          break;

        default:
          program.wappCode = 'RU';
          break;
      }


    }

    return program;
  }

  public getCrosswalkProgram = (programCode: string) => new Promise<Program>(resolve => {
    this.http.get<Program>(`/rest/programs/${programCode}/crosswalk`, {responseType: 'json'})
      .pipe(
        switchMap((disclosure: Program) => {
          return of(disclosure).toPromise();
        }),
      ).toPromise().then(res => {
      resolve(res);
    });
  })

  private resolveProgramsEndpoint(nestedProgramsResponse: string) {
    // sometimes returns from the API as an object depending on environment and we dont know why
    const response = typeof nestedProgramsResponse !== 'object' ? JSON.parse(nestedProgramsResponse) : nestedProgramsResponse;
    Object.assign(this.pickerPrograms, response);
    this.programsLoaded.next(true);
  }
}
