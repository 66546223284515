<div class="prefill" [ngClass]="{'show': prefilling}"><h2>{{resumeText}}</h2></div>

<app-environment-warning></app-environment-warning>
<form [formGroup]="applicationForm" class="mb-5">

  <!-- YOUR DEGREE -->
  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'yourDegree',
      'hide': activeSectionName !== 'yourDegree'
    }"

    class="col form-group no-padding form-section"
    id="yourDegree"
    formGroupName="yourDegree"
    role="tabpanel"
    id="yourDegree-tab"
    [attr.aria-labelledby]="'yourDegree'"
  >

    <h1 tabindex="0" class="yourDegree screen-reader-only">Your Degree</h1>

    <app-decorative-card
      class="mb-5 d-block"
      (click)="maybeScrollToCampus()"
      [hidden]="cookieService.isVwoTest('548', '3')"
    >
      <h2>Welcome to your <br class="mobile-show"> Liberty <br class="mobile-hide">University {{applicationService.spcApp ? 'non-degree' : ''}} application</h2>

      <ng-container *ngIf="!cookieService.isVwoTest('548')">
        <p>You’re only a few steps away from pursuing your <br class="mobile-hide">dreams!</p>
      </ng-container>

      <ng-container *ngIf="cookieService.isVwoTest('548', '2')">
        <p>To complete your application you will need to provide:</p>
        <ul>
          <li><b>Previous education information</b></li>
          <li><b>Personal contact information</b></li>
        </ul>
      </ng-container>

    </app-decorative-card>

    <h1 *ngIf="cookieService.isVwoTest('548', '3')">Welcome to your Liberty University application</h1>

    <app-alert
      *ngIf="cookieService.isVwoTest('548', '3')"
      [larger]="true"
      [clean]="true"
      [type]="'no-icon alert-light-blue'"
      (click)="scrollToControl('campus-anchor')"
    >
      <p>To complete your application you will need to provide:</p>
      <ul>
        <li><b>Previous education information</b></li>
        <li><b>Personal contact information</b></li>
      </ul>
    </app-alert>

    <app-form-error *ngIf="showYourDegreeErrors" [preStageErrors]="true" [formGroup]="applicationForm.get('yourDegree')"></app-form-error>

    <app-location-radio *ngIf="!applicationService.spcApp && !applicationService.festivalsApp" [formGroup]="applicationForm.get('yourDegree')"></app-location-radio>

    <span id="level-anchor"></span>

    <app-degree-level-radio *ngIf="!applicationService.spcApp && !applicationService.festivalsApp" [formGroup]="applicationForm.get('yourDegree')"
      [hidden]="applicationForm.get('yourDegree').get('campus').value === ''" ></app-degree-level-radio>

    <span id="degree-picker-anchor"></span>

    <app-degree-picker *ngIf="!applicationService.spcApp && !applicationService.festivalsApp" [hidden]="applicationForm.get('yourDegree').get('degree_level').value === ''" [formGroup]="applicationForm.get('yourDegree')"
      ></app-degree-picker>

    <app-custom-program-questions [hidden]="!programService.getCurrentProgram().programCode"
      [formGroup]="applicationForm.get('yourDegree').get('custom_program_questions')"></app-custom-program-questions>

    <app-start-term-select [formGroup]="applicationForm.get('yourDegree')"></app-start-term-select>

    <app-continue-button
      class="mt-3"
      (click)="navigationService.emitActiveSectionNext('previousEducation')"
      *ngIf="programService.getCurrentProgram().programCode"
      [ngClass]="{'mobile-hide' : programService.getCurrentProgram().programCode === undefined || programService.getCurrentProgram().programCode.length < 1}"
    ></app-continue-button>

  </div>

  <!-- PREVIOUS EDUCATION -->
  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'previousEducation',
      'hide' : activeSectionName !== 'previousEducation'
    }"

    class="col form-group form-section"
    id="previousEducation"
    formGroupName="previousEducation"
    role="tabpanel"
    id="previousEducation-tab"
    [attr.aria-labelledby]="'previousEducation'"
  >
    <app-previous-education
      (emitHsDiplomaFName)="hsDiplomaFNameUpdated($event)"
      (emitHsDiplomaLName)="hsDiplomaLNameUpdated($event)"
      [formGroup]="applicationForm.get('previousEducation')"
      [showErrors]="showPreviousEducationErrors"
    >
    </app-previous-education>

    <app-continue-button
      class="mt-3"
      (click)="navigationService.emitActiveSectionNext('personalInformation')"
      *ngIf="programService.getCurrentProgram().programCode !== ''"
      [ngClass]="{'mobile-hide' : programService.getCurrentProgram().programCode === undefined || programService.getCurrentProgram().programCode.length < 1}">
    </app-continue-button>

  </div>


  <!--  PERSONAL INFORMATION-->
  <div
    [ngClass]="{
     'displayed' : activeSectionName === 'personalInformation',
      'hide' : activeSectionName !== 'personalInformation'
    }"

    class="col form-group form-section"
    id="personalInformation"
    formGroupName="personalInformation"
    role="tabpanel"
    id="personalInformation-tab"
    [attr.aria-labelledby]="'personalInformation'"
  >
    <app-form-error *ngIf="showPersonalInformationErrors" role="alert" [preStageErrors]="true" [formGroup]="applicationForm.get('personalInformation')"></app-form-error>

    <h1 tabindex="0" class="personalInformation screen-reader-only">Personal Information</h1>



    <app-personal-information [formGroup]="applicationForm.get('personalInformation')"></app-personal-information>

    <app-continue-button
      class="mt-3"
      (click)="navigationService.emitActiveSectionNext('agreeSubmit')"
      *ngIf="programService.getCurrentProgram().programCode !== ''"
    >
    </app-continue-button>

  </div>

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'agreeSubmit',
      'hide' : activeSectionName !== 'agreeSubmit'
    }"

    class="col form-group form-section"
    id="agreeSubmit"
    formGroupName="agreeSubmit"
    role="tabpanel"
    id="agreeSubmit-tab"
    [attr.aria-labelledby]="'agreeSubmit'"
  >

    <h1 tabindex="0" class="agreeSubmit screen-reader-only">Agree and Submit</h1>

    <app-form-error *ngIf="formService.submitted && activeSectionName === 'agreeSubmit' && !showAgreeAndSubmitErrors" [formGroup]="applicationForm">
    </app-form-error>
    <app-form-error *ngIf="activeSectionName === 'agreeSubmit' && showAgreeAndSubmitErrors" [preStageErrors]="true" [formGroup]="applicationForm.get('agreeSubmit')"></app-form-error>

    <fieldset>
      <legend>
        <h2 class="large-header d-inline-block">Agree & Submit</h2>
      </legend>
      <app-summary [selectedProgram]="programService.getCurrentProgram()"></app-summary>
    </fieldset>

    <app-hear-about-us-dropdown [formGroup]="applicationForm.get('agreeSubmit').get('hear_about_us_select')">
    </app-hear-about-us-dropdown>


    <fieldset>

      <legend>
        <h2 class="header-text" *ngIf="programService.getCurrentProgram().campCode === 'R'">Tell us about your personal history.</h2>
      </legend>


      <app-probation-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-probation-radio>

      <app-alcohol-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-alcohol-radio>

      <app-dismissal-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-dismissal-radio>

      <app-felon-radio [formGroup]="applicationForm.get('agreeSubmit')"></app-felon-radio>

      <app-title-ix [formGroup]="applicationForm.get('agreeSubmit')"></app-title-ix>

    </fieldset>

    <app-tshirt [formGroup]="applicationForm.get('agreeSubmit')"></app-tshirt>

    <app-institutional-agreement [formGroup]="applicationForm.get('agreeSubmit')"></app-institutional-agreement>

    <app-tcparadio [formGroup]="applicationForm.get('agreeSubmit').get('tcpa_question')"></app-tcparadio>


    <div class="form-success alert alert-danger mt-3"
      *ngIf="!applicationService.submitting && applicationService.submitted && !applicationService.submitSuccess">
      Application Submission Error: Unable to reach ApplyLU API (Reach out to Support with AppId
      #{{appIdService.getAppId()}})
    </div>

    <div style="position: relative;">
      <button
        *ngIf="navigationService.checkForEnrollmentDeposit && !applicationService.spcApp && !applicationService.festivalsApp"
        type="button"
        tabindex="0"
        (click)="navigationService.emitActiveSectionNext('optional')"
        class="enrollmentDeposit continueButton mb-3"
        [attr.disabled]="applicationService.submitting ? 'disabled' : null"
      >
      Continue to Optional Section
      </button>

      <button
        type="button"
        class="continueButton"
        (click)="submitEvent($event); showAgreeAndSubmitErrors = false"
        [attr.disabled]="applicationService.submitting ? 'disabled' : null"
      >
        <span>
          Submit Application
          <app-icon *ngIf="!applicationService.submitting" class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
          <app-loading-ring *ngIf="applicationService.submitting"></app-loading-ring>
        </span>
      </button>

    </div>

  </div>

  <app-data-fields [formGroup]="applicationForm.get('data_fields')"></app-data-fields>

  <div
    [ngClass]="{
      'displayed' : activeSectionName === 'optional',
      'hide' : activeSectionName !== 'optional'
    }"
    class="col form-group form-section"
    id="optional"
    formGroupName="optional"
    role="tabpanel"
    id="optional-tab"
    [attr.aria-labelledby]="'optional'"
  >

    <h1 tabindex="0" class="optional screen-reader-only">Optional</h1>

    <app-form-error *ngIf="formService.submitted && activeSectionName === 'optional'" [formGroup]="applicationForm"></app-form-error>

    <div *ngIf="cookieService.isVwoTest('514')" (click)="navigationService.emitActiveSectionNext('agreeSubmit')" class="goBackContainer">
      <app-icon *ngIf="!applicationService.submitting" class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
      <span>Back to Agree &amp; Submit</span>
    </div>

    <app-optional-essay-questions [hidden]="!(formService.getCurrentCampus() === 'R' && programService.getCurrentProgram().levelCode === 'UG')" [formGroup]="applicationForm.get('optional').get('optional_essay_questions')"></app-optional-essay-questions>


    <button
      type="button"
      class="continueButton"
      (click)="submitEvent($event)"
      [attr.disabled]="applicationService.submitting ? 'disabled' : null"
    >
      <span>
        Submit Application &amp; Essay
        <app-icon *ngIf="!applicationService.submitting" class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
        <app-loading-ring *ngIf="applicationService.submitting"></app-loading-ring>
      </span>
    </button>

    <button
      *ngIf="cookieService.isVwoTest('514')"
      type="button"
      class="essaySection continueButton"
      (click)="submitEvent($event); applicationService.justSubmitEssay = true; applicationService.submitting = false"
      [attr.disabled]="applicationService.justSubmitEssay ? 'disabled' : null"
    >
      <span>
        Skip &amp; Submit Application Only
        <app-icon *ngIf="!applicationService.justSubmitEssay" class="continueArrow" [icon]="'side-white-arrow'"></app-icon>
        <app-loading-ring [circleColor]="'#0059C0'" *ngIf="applicationService.justSubmitEssay"></app-loading-ring>
      </span>
    </button>

  </div>

</form>
<div *ngIf="displayGimmeCaptcha">
  <button id="get-captcha" class="mt-2" (click)="getCaptchaToken()">Gimme a Captcha token!</button>

  <code id="captcha-code" *ngIf="testCaptchaToken.length > 0">{{testCaptchaToken}}</code>

  <code id="verify-captcha-code" *ngIf="testCaptchaResponse">{{testCaptchaResponse}}</code>
</div>
<app-little-popup></app-little-popup>

<app-app-object-toggle class="col" [passedForm]="applicationForm"></app-app-object-toggle>
