
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HttpClientXsrfModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ApplyluMsalModule} from './applylu-msal/applylu-msal.module';
import {SharedModule} from './shared/shared.module';
import {FormModule} from './form/form.module';
import {SidebarModule} from './sidebar/sidebar.module';
import {AppIdService} from './shared/provider/app-id.service';
import {ApplicationService} from './shared/service/application.service';
import {thankYouInit} from './thank-you/thank-you.component';
import {UnauthComponent} from './unauth/unauth.component';
import {CookieService} from './shared/service/cookie.service';
import {IncompleteAppService} from './shared/service/incomplete-app.service';
import {MainComponent} from './main/main.component';
import {ThankYouModule} from './thank-you/thank-you.module';
import {ModalModule} from './modal/modal.module';
import {RouterService} from './shared/service/router.service';
import {InputModule} from './form/input/input.module';
import {
  SearchableSelectDropDownModule
} from './form/input/generic/searchable-select-drop-down/searchable-select-drop-down.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProgramService} from './shared/service/program.service';
import {ProgressBarModule} from './shared/component/progress-bar/progress-bar.module';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {IconModule} from './shared/component/icon/icon.module';
import {TokenService} from './shared/service/token.service';
import {AuthenticationService} from './shared/service/authentication.service';
import {LoginBannerComponent} from './login/login-banner/login-banner.component';
import {FormService} from './shared/service/form.service';
import {AnalyticsService} from './shared/service/analytics.service';
import {TreeModule} from '@ali-hm/angular-tree-component';
import {environment} from '../environments/environment';
import {OtpModalModule} from './modal/otp-modal/otp-modal.module';


let providerArray: any[];

if (window.location.href.indexOf('/thank-you') > -1 || window.location.href.includes('UPAY_SITE_ID')) {
  providerArray = [
    {
      provide: APP_INITIALIZER,
      useFactory: thankYouInit,
      deps: [AppIdService, ApplicationService, CookieService, IncompleteAppService, RouterService, ProgramService, FormService, AnalyticsService],
      multi: true
    }
    // { provide: ErrorHandler, useClass: ErrorHandlerService },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, }
  ];

} else {
  providerArray = [
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [TokenService, CookieService, AuthenticationService, AnalyticsService],
      multi: true
    },
    // { provide: ErrorHandler, useClass: ErrorHandlerService },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true, }
  ];

}

export function appInit(
  tokenService: TokenService,
  cookieService: CookieService,
  authService: AuthenticationService, // this needs to stay here so that subscriptions to Msal.BroadcastService are set up to catch tokens
  analyticsService: AnalyticsService
) {

  cookieService.sanitizeCookies();

  // app_initializer expects a returned function
  return async () => {

    analyticsService.initVwo();

    await tokenService.getToken();
    if (!environment.isAgent) {
      // set up recaptcha enterprise script
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptchaKey}`;
      document.head.appendChild(script);
    }
    try {
      await analyticsService.initGooglePlaces();
    } catch (error) {
      console.error('Error initializing Google Places:', error);
    }
  };

}

@NgModule({
  declarations: [
    AppComponent,
    UnauthComponent,
    MainComponent,
    MaintenanceComponent,
    LoginBannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ApplyluMsalModule,
    FormModule,
    SharedModule,
    SidebarModule,
    ThankYouModule,
    HttpClientXsrfModule,
    InputModule,
    SearchableSelectDropDownModule,
    ModalModule,
    BrowserAnimationsModule,
    ProgressBarModule,
    IconModule,
    TreeModule,
    OtpModalModule
  ],
  providers: providerArray,
  bootstrap: [AppComponent]
})

export class AppModule {
}
