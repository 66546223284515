export enum EProgLevel {
  Bachelor = 'Bachelor',
  Master = 'Master',
  Doctorate = 'Doctorate',
  Associate = 'Associate',
  Certificate = 'Certificate',
  LessthanAssociate = 'LessthanAssociate'
}

export enum ELevelCategory {
  Undergrad = 'Undergrad',
  Graduate = 'Graduate'
}

export interface ProgLevelObj {
  lev: EProgLevel;
  cat: ELevelCategory;
}
