import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EProgLevel } from 'src/app/shared/model/prog-level.model';
import { DomService } from 'src/app/shared/service/dom.service';
import { FormService } from 'src/app/shared/service/form.service';
import {CookieService} from '../../../shared/service/cookie.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-degree-level-radio',
  templateUrl: './degree-level-radio.component.html',
  styleUrls: ['./degree-level-radio.component.scss']
})
export class DegreeLevelRadioComponent implements OnInit {

  @Input() formGroup: FormGroup;

  @ViewChild('levelRadioContainer') levelRadioContainer: ElementRef;

  currentLevel = '';
  showMobileVersionOnDesktop = false;

  constructor(
    public domService: DomService,
    private formService: FormService,
    public route: ActivatedRoute,
    public cookieService: CookieService
  ) { }

  ngOnInit(): void {

    this.formGroup.addControl('degree_level', new FormControl<string>(''));

    this.route.queryParams.subscribe(params => {
      if (params.l) {
        if (params.l === 'a') {
          this.formGroup.get('degree_level').setValue(EProgLevel.Associate);
          this.formService.updatedLevel.next(EProgLevel.Associate);
        } else if (params.l === 'b') {
          this.formGroup.get('degree_level').setValue(EProgLevel.Bachelor);
          this.formService.updatedLevel.next(EProgLevel.Bachelor);
        } else if (params.l === 'c') {
          this.formGroup.get('degree_level').setValue(EProgLevel.Certificate);
          this.formService.updatedLevel.next(EProgLevel.Certificate);
        } else if (params.l === 'd') {
          this.formGroup.get('degree_level').setValue(EProgLevel.Doctorate);
          this.formService.updatedLevel.next(EProgLevel.Doctorate);
        } else if (params.l === 'm') {
          this.formGroup.get('degree_level').setValue(EProgLevel.Master);
          this.formService.updatedLevel.next(EProgLevel.Master);
        } else if (params.l === 'l') {
          this.formGroup.get('degree_level').setValue(EProgLevel.LessthanAssociate);
          this.formService.updatedLevel.next(EProgLevel.LessthanAssociate);
        }
      }
    });

  }

  emitNewLevel(level: string) {
    let newLevel = EProgLevel[level];
    if (!newLevel) {
      newLevel = '';
    }

    this.showMobileVersionOnDesktop = false;
    this.currentLevel = newLevel;
    this.formGroup.get('degree_level').setValue(newLevel, { onlySelf: true });

    if (window.innerWidth < 767 && newLevel) {
      setTimeout(() => {
        const levelHeader = this.domService.selectElementAsRoot('#level-header');
        this.domService.scrollIntoView(levelHeader);
      }, 1);
    }

    // setTimeout(() => {
    this.formService.updatedLevel.next(level);
    // }, 250);

    return false; // needed for mobile functionality to work properly

  }

  protected readonly environment = environment;
}
