// this service handles sending application submissions to our ApplyLU API
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';

import { Application } from '../model/application.model';
import { AppIdService } from '../provider/app-id.service';
import { ApplicationRequest } from '../model/application-request.model';
import { Phone } from '../model/application/phone.model';
import { IncompleteApp } from '../model/applyLUApiObjects/incomplete-app.model';
import { CustomAnswer } from '../model/application/custom-answer.model';
import { debounceTime, distinct, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import * as Bowser from 'bowser';
import { AnalyticsService } from './analytics.service';

import { ProgramService } from './program.service';
import { ApplicationTransformerService } from './application-transformer.service';
import { Code } from '../model/application/code.model';
import { Lead } from '../model/lead';
import { environment } from 'src/environments/environment';
import { DateService } from './date.service';
import { AuthenticationService } from './authentication.service';
import { GenerateLuidResponse } from '../model/applyLUApiObjects/generate-luid-response.model';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  static application: any;
  static submitSuccess: boolean;
  application = new Application();

  public updateSent = new Subject<Application>();
  public updatedPhone = new Subject<Phone>();
  public prefillApplication = new Subject<Application>();
  public acodesUpdated = new Subject<Code[]>();
  public prefillLead = new Subject<Lead>();
  public spcApp = false;
  public spchMajor = false;
  public festivalsApp = false;
  public applicationPhone = new Phone();
  public applicationEmail = '';

  public submitted = false;
  public submitting = false;
  public submitSuccess = false;

  public justSubmitEssay = false;

  // a lot of this should be moved out of the application service and into the data-fields instead. They serve no functionality and only collect small pieces of data to pass to the app
  public generateLuidresponse = new GenerateLuidResponse();
  public webId = '';
  public submissionSequenceNo = '';
  public pidm: string;
  public deviceInfo = '';
  public createDate = '';
  public userIp = '';
  public currentUrl = '';
  public psmt = '';
  public crmid = '';
  public gtmReferralChannel = '';
  public referringURL = '';
  public refereesAidm = '';
  public extraAcodes: Code[] = []; // this allows some components that aren't formControls add some acodes, will be combined into allAcodes with each form update
  public allAcodes: Code[] = [];
  public Q204UrlParams = '';
  public ceeb = '';
  public isTest = false;
  public tid = '';
  public isBlockedOnlinePhone = false;
  public isBlockedResPhone = false;
  public isBlockedOnlineEmail = false;
  public isBlockedResEmail = false;

  private applicationBaseUrl = '/rest/applications';

  constructor(
    private http: HttpClient,
    private appIdService: AppIdService,
    private programService: ProgramService,
    private analyticsService: AnalyticsService, // including this here will intialize analytics if able
    private applicationTransformerService: ApplicationTransformerService,
    private authService: AuthenticationService,
    private dateService: DateService,
    private cookieService: CookieService
  ) {
    this.application.emails = [];

    this.deviceInfo = this.gatherDeviceInfo();

    this.updatedPhone.subscribe(phone => {
      this.applicationPhone = phone;
    });

  }

  resumeApp(previousIncompleteAppObj: IncompleteApp) {
    const appJson = typeof previousIncompleteAppObj.appJson === 'string' ? JSON.parse(previousIncompleteAppObj.appJson) : previousIncompleteAppObj.appJson;

    const prefillApp = new Application();
    prefillApp.highSchools = [];
    prefillApp.priorColleges = [];
    prefillApp.addresses = [];
    prefillApp.phones = [];
    prefillApp.citizenship = '';

    Object.assign(prefillApp, appJson);

    this.appIdService.setProperties(previousIncompleteAppObj);

    this.application = prefillApp;

    this.prefillApplication.next(prefillApp);
    this.updateSent.next(prefillApp);
    this.cookieService.startNewAppSubject.next(true);
  }

  gatherDeviceInfo() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const osInfo = browser.getOSName() + browser.getOSVersion();
    const platform = browser.getPlatformType();
    const browserName = browser.getBrowserName();
    const browserVersion = browser.getBrowserVersion();
    return '{ "dev_os": "' + osInfo + '", "dev_type": "' + platform + '", "dev_browser": "' + browserName + '", "dev_browser_version": "' + browserVersion + '" }';
  }

  public addAcode(acode: string) {
    const acodeToPush = new Code(acode);
    const alreadyHasCode: boolean = this.extraAcodes.some(thisAcode => thisAcode.code === acode.replace(' ', ''));
    if (!alreadyHasCode) {
      this.extraAcodes.push(acodeToPush);
    }
  }

  public removeAcode(acode: string) {
    const acodeIndex: number = this.extraAcodes.findIndex(item => {
      return item.code === acode;
    });
    if (acodeIndex >= 0) { // acodeIndex will be -1 if no match is found
      this.extraAcodes.splice(acodeIndex, 1);
    }
  }

  public getAllAcodes() {
    return this.allAcodes;
  }

  public addAttribute(attributeCode: string) {
    this.application.addAttribute(attributeCode);
  }

  public removeAttribute(attributeCode: string) {
    this.application.removeAttribute(attributeCode);
  }

  public setCustomAnswer(answer: string, questionNumber: string) {
    this.application.setCustomAnswer(answer, questionNumber);
  }

  public getAnswer(answerNumber: string) {
    if (this.application) {
      const findAnswer = this.application.customAnswers.find((thisAnswer: CustomAnswer) => thisAnswer.questionNumber === answerNumber);
      return findAnswer ? findAnswer.answer : '';
    }
    return '';
  }

  getAppPhone(): Phone {
    return this.applicationPhone;
  }

  async prepareAppObject(formVals, submission = false) {

    const returnedApp: Application = await this.applicationTransformerService.transformFormToApp(formVals);

    const wapp = returnedApp.wapp;

    if ((this.spcApp || this.festivalsApp)) {
      returnedApp.wapp = this.programService.gatherWappCode('UG', 'Undergraduate', 'Bachelor', 'R', returnedApp.priorColleges, wapp);
    }

    if (this.appIdService.getCreatedTimestamp()) {
      this.createDate = this.dateService.formatDate(this.appIdService.getCreatedTimestamp());
      returnedApp.setCustomAnswer(this.createDate, '309');
    }

    returnedApp.setCustomAnswer(this.deviceInfo, '241');
    returnedApp.setCustomAnswer(this.appIdService.getPrefixedAppId(), '300');
    returnedApp.setCustomAnswer(this.currentUrl, '244');
    returnedApp.setCustomAnswer(this.psmt, '221');
    returnedApp.setCustomAnswer(this.refereesAidm, '302');
    if (environment.isAgent && this.authService.getUsername()) {
      returnedApp.setCustomAnswer(this.authService.getUsername(), '239');
    }
    returnedApp.setCustomAnswer(document.referrer, '240');
    returnedApp.setCustomAnswer(this.ceeb, '251');
    returnedApp.setCustomAnswer(this.crmid, '250');
    returnedApp.setCustomAnswer(this.tid, '246');
    returnedApp.setCustomAnswer(this.gtmReferralChannel, '337');
    returnedApp.setCustomAnswer(this.referringURL, '339');

    if (this.programService.getCurrentProgram().programAttr) {
      returnedApp.addAttribute(this.programService.getCurrentProgram().programAttr);
    }

    if (this.spcApp) {
      returnedApp.setCustomAnswer('SPC', '249');
    } else if (this.festivalsApp) {
      returnedApp.setCustomAnswer('FEST', '249');
    }

    if (!environment.isAgent) {
      returnedApp.setCustomAnswer('ApplyLU', '61');
      returnedApp.setCustomAnswer(window.navigator.userAgent, '322');
    }
    // add URL parameters to question 204
    returnedApp.setCustomAnswer(this.Q204UrlParams, '204');

    // combine any appAcodes with any prepared in the transformer
    returnedApp.sources = arrayUnique(returnedApp.sources.concat(this.extraAcodes));
    this.allAcodes = returnedApp.sources;
    this.acodesUpdated.next(this.allAcodes);

    // save IP address for last
    returnedApp.ipAddress = this.appIdService.getIpAddr();

    return returnedApp;
  }

  public async updateStudentApplication(formVals: any) {
    await this.updateApplication(formVals);
  }

  public async updateAgentApplication(formVals: any) {
    await this.updateApplication(formVals, true);
  }

  private async updateApplication(formVals: any, isAgent: boolean = false) {
    if (!this.submitting && !this.submitSuccess && this.appIdService.getAppId() && this.appIdService.getAppId() !== 'Unavailable') {
      this.application = await this.prepareAppObject(formVals);

      // ensure this happens after all modifications to this.application are done
      const applicationRequestBody = this.prepareIncompleteAppRequestBody();
      applicationRequestBody.formObject = JSON.stringify(formVals);
      applicationRequestBody.ipAddress = this.appIdService.getIpAddr();

      if (this.isTest) {
        applicationRequestBody.testApp = 'Y';
      } else {
        applicationRequestBody.testApp = 'N';
      }

      if (this.festivalsApp) {
        applicationRequestBody.festivalApp = 'Y';
      } else {
        applicationRequestBody.festivalApp = 'N';
      }

      this.updateSent.next(this.application);
      const appIDString = this.appIdService.getAppId();
      const pathSuffix = isAgent ? '/agent' : '';

      this.http
        .put<void>(this.applicationBaseUrl + '/' + appIDString + pathSuffix, applicationRequestBody)
        .pipe(
          takeUntil(this.updateSent),
          distinct(), // this will prevent updates being sent to the API if the form value isn't changed
          debounceTime(1000), // also give a pause between valueChanges before we send the update to prevent requests from being spammed
          switchMap((appForm) => of(appForm))
        )
        .subscribe();
    } else if (!this.submitting && !this.submitSuccess && this.appIdService.getAppId() && this.appIdService.getAppId() === 'Unavailable') {
      this.appIdService.load().subscribe();
    }
  }


  public submitApplication = async (formVals: any, token?: string) => {
    if (this.submitting || this.submitSuccess || !this.appIdService.getAppId()) {
      return false;
    }

    this.submitted = true;
    this.submitting = true;

    this.application = await this.prepareAppObject(formVals, true);

    // ensure this happens after all final modifications to this.application are done
    const applicationRequestBody = this.prepareIncompleteAppRequestBody();
    applicationRequestBody.formObject = JSON.stringify(formVals);
    applicationRequestBody.ipAddress = this.appIdService.getIpAddr();
    applicationRequestBody.testApp = this.isTest ? 'Y' : 'N';
    this.application.submitStatus = this.isTest ? 'N' : 'Y';
    applicationRequestBody.festivalApp = this.festivalsApp ? 'Y' : 'N';

    const appIDString = this.appIdService.getAppId();

    const requestOptions: any = { body: applicationRequestBody, responseType: 'text' };
    const isAgentSubmission = environment.isAgent;
    const isTokenProvided = Boolean(token);
    if (!isAgentSubmission && isTokenProvided) {
      requestOptions['headers'] = { 'X-APPLYLU-CT': token };
    }

    try {

      const submissionResponse: SubmissionResponse = await this.http.post<SubmissionResponse>(
        `${this.applicationBaseUrl}/${appIDString}${isAgentSubmission ? '/agent' : ''}`,
        applicationRequestBody,
        requestOptions
      ).pipe(
        map((response: HttpResponse<any> | any) => {
          const contentType = response.headers?.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.body; // If response is already JSON, return as it is
          } else {
            return JSON.parse(response); // Parse the response if it's a string
          }
        })
      ).toPromise();

      // error accessing these properties
      this.webId = submissionResponse?.webId;
      this.submissionSequenceNo = submissionResponse?.submissionSequenceNo;

      if (!submissionResponse) {
        this.webId = 'unavailable';
        this.submissionSequenceNo = '';
      }

      this.sendGtmCustomEvent(this.webId);

      if (!this.isTest) {

        const luidResponse: GenerateLuidResponse | HttpEvent<GenerateLuidResponse> = await this.http
          .request<GenerateLuidResponse>('get', `${this.applicationBaseUrl}/${appIDString}/luid`)
          .toPromise();

        Object.assign(this.generateLuidresponse, luidResponse);

        setTimeout(() => {
          this.stopSubmitting();
        }, 100);

      } else {
        this.stopSubmitting(true);
      }

      return true;
    } catch (error) {
      if (error.status === 503) {
        this.stopSubmitting(false);
      } else {
        this.stopSubmitting();
      }
      return false;
    }


  }

  public getMilitaryVerification(appId: string, code: string, redirectUrl: string): Observable<any> {
    return this.http.request<string>('post', `rest/id-me/verify/${code}?appId=${appId}`, { body: redirectUrl });
  }

  private sendGtmCustomEvent(webId: string): void {
    if (this.analyticsService.gtmId !== '') {
      const dataLayerObj = {
        event: 'GAevent',
        eventCategory: 'Submit - Application',
        submittedEmail: this.application.emails[0].emailAddress,
        submittedPhone: this.application.phones[0].area + this.application.phones[0].phoneNumber,
        eventWebId: webId,
        eventAppId: this.appIdService.getPrefixedAppId(),
        campus: this.getCampus(),
      };
      this.analyticsService.dataLayerPush(dataLayerObj);
    }
  }

  private getCampus(): string {
    const campus = this.programService.getCampusByProgramCode(this.application.majorFoses[0].programCode);
    return campus === 'D' ? 'Online' : campus === 'R' ? 'Residential' : '';
  }

  private stopSubmitting(success = true) {
    this.submitSuccess = success;
    this.submitting = false;
  }

  private prepareIncompleteAppRequestBody(): ApplicationRequest {
    const returnedAppBody = new ApplicationRequest();
    const agentUsername = this.authService.getUsername();
    const appIDString = this.appIdService.getAppId();

    const applicationCopy = new Application();
    Object.assign(applicationCopy, this.application);

    returnedAppBody.appJson = JSON.stringify(applicationCopy);

    if (applicationCopy.emails && applicationCopy.emails[0] && applicationCopy.emails[0].emailAddress) {
      returnedAppBody.email = applicationCopy.emails[0].emailAddress;
    }

    if (applicationCopy.phones && applicationCopy.phones[0]) {
      returnedAppBody.phone = applicationCopy.getCleanFullAppPhone();
    }

    returnedAppBody.id = parseInt(appIDString, 10);

    if (environment.isAgent && agentUsername) {
      returnedAppBody.agentUsername = agentUsername;
    }
    if (this.pidm) {
      returnedAppBody.pidm = this.pidm;
    }

    return returnedAppBody;
  }
}


function arrayUnique(array) {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) {
        a.splice(j--, 1);
      }
    }
  }

  return a;
}

class SubmissionResponse {
  webId: string;
  submissionSequenceNo: string
}
